var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-back",
      style: {
        background:
          "url(" +
          require("@/assets/images/testlogin_back.gif") +
          ")" +
          "no-repeat"
      }
    },
    [
      _c("img", {
        staticClass: "logo",
        attrs: { src: require("@/assets/images/login-logo.png"), alt: "" }
      }),
      _c("div", { staticClass: "box_outer" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v("智能化多媒体管理和生产系统")
        ]),
        _c(
          "div",
          { staticClass: "login-container" },
          [
            _c("div", { staticClass: "login-or-register" }, [
              _vm._v("验证邮箱")
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v("已经将验证邮件发送至邮箱：")
            ]),
            _c("div", { staticClass: "text1" }, [_vm._v(_vm._s(_vm.email))]),
            _c("div", { staticClass: "text2" }, [
              _vm._v("点击邮件中链接即可完成验证")
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.loginEmail } },
              [_vm._v("登录邮箱")]
            ),
            _c("div", { staticClass: "bottom" }, [
              _c(
                "div",
                { staticClass: "a" },
                [
                  _c(
                    "router-link",
                    {
                      staticStyle: { "text-decoration": "none" },
                      attrs: { to: { name: "Register" } }
                    },
                    [_vm._v("重新填写邮箱")]
                  )
                ],
                1
              ),
              _vm.show == 0
                ? _c(
                    "div",
                    { staticClass: "reSend", on: { click: _vm.reSendEmail } },
                    [_vm._v(" 重新发送验证邮件 ")]
                  )
                : _vm._e(),
              _vm.show == 1
                ? _c("div", [_vm._v(_vm._s(_vm.time) + "s后重新发送邮件")])
                : _vm._e()
            ])
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }