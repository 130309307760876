<template>
  <!-- 登录背景 -->
  <div
    class="login-back"
    :style="{
      background:
        'url(' + require('@/assets/images/testlogin_back.gif') + ')' + 'no-repeat',
    }"
  >
    <!-- 登录logo -->
    <img class="logo" src="@/assets/images/login-logo.png" alt="" />
    <!-- 标题 -->
    <div class="box_outer">
      <div class="title">智能化多媒体管理和生产系统</div>
      <!-- 容器 -->
      <div class="login-container">
        <!-- 标题-->
        <div class="login-or-register">验证邮箱</div>
        <div class="text">已经将验证邮件发送至邮箱：</div>
        <div class="text1">{{ email }}</div>
        <div class="text2">点击邮件中链接即可完成验证</div>
        <el-button type="primary" @click="loginEmail">登录邮箱</el-button>
        <div class="bottom">
          <div class="a">
            <router-link style="text-decoration: none" :to="{ name: 'Register' }"
              >重新填写邮箱</router-link
            >
          </div>
          <div class="reSend" v-if="show == 0" @click="reSendEmail">
            重新发送验证邮件
          </div>
          <div v-if="show == 1">{{ time }}s后重新发送邮件</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.el-message{
left: 90%;
min-width: 220px;
}
</style>
<style lang="scss" scoped>
.login-back {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover !important;
  // 登录背景logo
  .logo {
    width: 11.5vw;
    height: 10.2vh;
    margin-top: 12vh;
    margin-right: 20.5vw;
  }
  .box_outer{
    margin-right: 12.49vw;
  }
  // 标题
  .title {
    font-size: 1.6vw;
    letter-spacing: 4px;
    text-align: center;
  }
  // 登录容器
  .login-container {
    margin-top: 6.8vh;
    width: 27.7vw;
    height: 47.6vh;
    background: rgba(57, 56, 70, 0.6);
    border-radius: 5px;
    .login-or-register {
      margin-left: 6.57vw;
      padding-top: 11.9vh;
      font-size:16px;
    }
    .text {
      margin-left: 6.57vw;
      margin-top: 2.9vh;
      font-size: 14px;
    }
    .text1 {
      margin-top: 1px;
      margin-left: 6.57vw;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.6);
    }
    .text2 {
     margin-top: 1.6vh;
      margin-left: 6.57vw;
      font-size: 14px;
    }

    .el-button--primary {
      width: 16.8vw;
      margin-left: 6.57vw;
      margin-top: 3vh;
    }
    .bottom {
      display: flex;
      width: 16.8vw;
      margin-left: 6.57vw;
      margin-top: 2.1vh;
      justify-content: space-between;
      .a {
        font-size: 14px;
        cursor: pointer;
      }
      .reSend {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
</style>
<script>
export default {
  name: "Login",
  data() {
    return {
      // 邮箱地址
      email: sessionStorage.getItem('Email'),
      // 重新发送邮件按钮跟倒计时切换
      show: 0,
      // 倒计时时间
      time: 60,
    };
  },
  methods: {
    // 登陆邮箱
    loginEmail() {
      this.email.indexOf('maixunbytes.com')>-1?window.open("https://qiye.163.com/login/", "_blank"):window.open("https://login.live.com/", "_blank")
    },
    // 重新发送验证邮件
    reSendEmail() {
      // 发送邮箱验证请求
      let formData = new FormData();
      formData.append("email", sessionStorage.getItem('Email'));
      formData.append("password", sessionStorage.getItem('PassWord'));
      this.$http({
        method: "POST",
        url: "accounts/register_user",
        data: formData,
      }).then((res) => {
        //发送成功
        if(res.data.status==0){
          // 展示倒计时60s
          this.countdown()
        }
        // 用户已经存在
        if(res.data.status==2){
          this.show=0
          this.$message({
            message: res.data.msg,
            type: 'error',
          })

        }
      }).catch(error => console.log(error));
    },
    // 倒计时函数
    countdown() {
      this.show = 1;
      this.time=60
      let timer = setInterval(() => {
        this.time = this.time - 1;
        if (this.time == 0) {
          this.show = 0;
          clearInterval(timer);
        }
      }, 1000);
    },
  }
};
</script>
